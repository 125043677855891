.main-footer {
  padding: 70px 0 16px;
  background-color: #1e1e1e;
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #ffffff;

  @media (max-width: 991px) {
    padding: 48px 0 98px;
  }
  .footer-row {
    display: grid;
    grid-template-columns: 3fr auto auto auto;
    gap: 62px;
    @media (max-width: 991px) and (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
      .footer-col:nth-of-type(1) {
        grid-area: 1/1/2/3;
        margin-bottom: 48px;
        gap: 0 24px;
      }
      .footer-col:nth-of-type(4) {
        grid-area: 2 / 1 / 3 / 5;
      }
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
      .footer-col:nth-of-type(1) {
        grid-area: 1 / 1 / 2 / 3;
        margin-bottom: 48px;
        gap: 0 24px;
      }
      .footer-col:nth-of-type(2) {
        grid-area: 2 / 1 / 3 / 2;
      }
      .footer-col:nth-of-type(3) {
        grid-area: 2 / 2 / 3 / 3;
      }
      .footer-col:nth-of-type(4) {
        grid-area: 3 / 1 / 4 / 3;
        margin-top: 56px;
      }
    }

    .footer-title {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 28.8px */
        margin-bottom: 25px;
      }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin: 0 0 10px;
        padding: 0;
        a {
          color: #fff;
          font-family: "Sora";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%; /* 28.8px */
          text-decoration: none;
        }
      }
    }
  }
  .footer-newsletter {
    @media (max-width: 991px) {
      .footer-title {
        text-align: center;
      }
    }
    .footer-newsletter-form {
      position: relative;
      max-width: 100%;
      border-radius: 20px;
      background: #1f2126;
      padding: 12px 156px 12px 20px;
      height: 80px;
      width: 460px;
      @media (max-width: 1199px) {
        width: 380px;
      }
      @media (max-width: 991px) {
        padding: 12px 20px;
        width: 100%;
        height: auto;
      }
      .form-control {
        color: #fff;
        font-family: "Manrope";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 28.8px */
        border: 0 none;
        height: 56px;
        background: transparent;
        @media (max-width: 991px) {
          margin-bottom: 20px;
          text-align: center;
        }
        &::placeholder {
          color: #5c606b;
        }
      }
      .btn {
        display: flex;
        padding: 16px 24px;
        align-items: center;
        gap: 12px;
        border-radius: 64px;
        background: #f4a4ec;
        color: #181d30;
        font-family: Chillax;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.36px;
        width: 100%;
        justify-content: center;
        @media (min-width: 992px) {
          width: auto;
          z-index: 1;
          position: absolute;
          inset: 12px 12px 12px auto;
        }
      }
    }
  }
  .footer-copyright {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    letter-spacing: 0.16px;
    text-align: center;
    margin-top: 82px;
    display: block;
    @media (max-width: 991px) {
      display: block;
      text-align: center;
      margin-top: 32px;
    }
  }
}
