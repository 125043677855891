.main-footer {
  padding: 70px 0 16px;
  background-color: #1e1e1e;
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #ffffff;
}
@media (max-width: 991px) {
  .main-footer {
    padding: 48px 0 98px;
  }
}
.main-footer .footer-row {
  display: grid;
  grid-template-columns: 3fr auto auto auto;
  gap: 62px;
}
@media (max-width: 991px) and (min-width: 768px) {
  .main-footer .footer-row {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
  .main-footer .footer-row .footer-col:nth-of-type(1) {
    grid-area: 1/1/2/3;
    margin-bottom: 48px;
    gap: 0 24px;
  }
  .main-footer .footer-row .footer-col:nth-of-type(4) {
    grid-area: 2/1/3/5;
  }
}
@media (max-width: 767px) {
  .main-footer .footer-row {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
  .main-footer .footer-row .footer-col:nth-of-type(1) {
    grid-area: 1/1/2/3;
    margin-bottom: 48px;
    gap: 0 24px;
  }
  .main-footer .footer-row .footer-col:nth-of-type(2) {
    grid-area: 2/1/3/2;
  }
  .main-footer .footer-row .footer-col:nth-of-type(3) {
    grid-area: 2/2/3/3;
  }
  .main-footer .footer-row .footer-col:nth-of-type(4) {
    grid-area: 3/1/4/3;
    margin-top: 56px;
  }
}
.main-footer .footer-row .footer-title {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 28.8px */
  margin-bottom: 25px;
}
.main-footer .footer-row ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.main-footer .footer-row ul li {
  margin: 0 0 10px;
  padding: 0;
}
.main-footer .footer-row ul li a {
  color: #fff;
  font-family: "Sora";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
  text-decoration: none;
}
@media (max-width: 991px) {
  .main-footer .footer-newsletter .footer-title {
    text-align: center;
  }
}
.main-footer .footer-newsletter .footer-newsletter-form {
  position: relative;
  max-width: 100%;
  border-radius: 20px;
  background: #1f2126;
  padding: 12px 156px 12px 20px;
  height: 80px;
  width: 460px;
}
@media (max-width: 1199px) {
  .main-footer .footer-newsletter .footer-newsletter-form {
    width: 380px;
  }
}
@media (max-width: 991px) {
  .main-footer .footer-newsletter .footer-newsletter-form {
    padding: 12px 20px;
    width: 100%;
    height: auto;
  }
}
.main-footer .footer-newsletter .footer-newsletter-form .form-control {
  color: #fff;
  font-family: "Manrope";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 28.8px */
  border: 0 none;
  height: 56px;
  background: transparent;
}
@media (max-width: 991px) {
  .main-footer .footer-newsletter .footer-newsletter-form .form-control {
    margin-bottom: 20px;
    text-align: center;
  }
}
.main-footer .footer-newsletter .footer-newsletter-form .form-control::placeholder {
  color: #5c606b;
}
.main-footer .footer-newsletter .footer-newsletter-form .btn {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 12px;
  border-radius: 64px;
  background: #f4a4ec;
  color: #181d30;
  font-family: Chillax;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.36px;
  width: 100%;
  justify-content: center;
}
@media (min-width: 992px) {
  .main-footer .footer-newsletter .footer-newsletter-form .btn {
    width: auto;
    z-index: 1;
    position: absolute;
    inset: 12px 12px 12px auto;
  }
}
.main-footer .footer-copyright {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.16px;
  text-align: center;
  margin-top: 82px;
  display: block;
}
@media (max-width: 991px) {
  .main-footer .footer-copyright {
    display: block;
    text-align: center;
    margin-top: 32px;
  }
}